


















import Vue from 'vue'
import ListingSearchPanel from '@/areas/listings/components/ListingSearchPanel.vue'
import SourceDataPanel from '@/areas/sourceData/components/SourceDataPanel.vue'
import ListingsCountPanel from '@/areas/listings/components/ListingsCountPanel.vue'
import SourceDataCountPanel from '@/areas/sourceData/components/SourceDataCountPanel.vue'
import ImportPanel from '@/areas/processes/components/ImportPanel.vue'

export default Vue.extend({
  components: {
    ListingSearchPanel,
    SourceDataPanel,
    ListingsCountPanel,
    SourceDataCountPanel,
    ImportPanel
  },
  data() {
    return {
      liveReadiness: null,
      liveReady: false,
      previewReadiness: null,
      previewReady: false
    }
  },
  computed: {
    error(): Error {
      const getters = this.$store.getters
      return (
        getters['SourceData/error'] ||
        getters['ProcessInsights/error'] ||
        getters['ListingHierarchies/error']
      )
    }
  }
})
