



































import Panel from '@/components/Panel.vue'
import IODataTableHeader from '@/models/IODataTableHeader'
import ODataTable from '@/components/ODataTable.vue'

export default {
  components: {
    Panel,
    ODataTable
  },
  data() {
    return {
      searchText: '',
      footerProps: {
        itemsPerPageOptions: [10]
      }
    }
  },
  computed: {
    headers(): IODataTableHeader[] {
      return [
        {
          text: 'Name',
          value: 'displayName',
          width: '70%',
          searchable: true
        },
        {
          text: 'Policy',
          value: 'policy',
          width: '30%'
        },
        {
          text: 'Start Date',
          value: 'startDate',
          width: '30%'
        }
      ]
    }
  },
  methods: {
  },
  created() {
  }
}
